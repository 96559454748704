import React from 'react';
import Seo from '../components/Seo';
import Layout from '../components/layout';
import Breadcrumbs from '../components/Breadcrumbs/breadcrumbs';
import './plantao-facil.css';

const LandingPage = () => {
    const crumbs = [
        { path: '/', label: 'Página Inicial' },
        { path: '/plantao-facil', label: 'Plantão Fácil' }
    ];

    function toggleAccordion(event) {
        const button = event.target;
        const answer = button.nextElementSibling;

        // Toggle the active class on the button
        button.classList.toggle('active');

        // Toggle the maxHeight to show or hide the answer
        if (answer.style.maxHeight) {
            answer.style.maxHeight = null;
        } else {
            answer.style.maxHeight = answer.scrollHeight + 'px';
        }
    }

    return (
        <Layout>
            <div className="plantao-page-container">
                <Seo
                    title="Plantão Fácil - Software para Gestão de Plantões Médicos"
                    description="O Plantão Fácil é o software ideal para médicos que desejam gerenciar seus plantões, agenda médica e recebíveis de forma simplificada. Descubra como podemos transformar sua prática médica."
                    keywords="gestão de plantões médicos, software para médicos, agenda médica online, recebíveis médicos, sistema de plantões para clínicas"
                />
                <Breadcrumbs crumbs={crumbs} />
                <main className="plantao-page-content">
                    {/* Hero Section */}
                    <header className="plantao-page-hero-section">
                        <div className="plantao-page-hero-content">
                            <h1>Software Completo para Gestão de Plantões Médicos</h1>
                            <p>Transforme a forma como você organiza seus plantões, gerencia atendimentos e acompanha seus recebíveis com o Plantão Fácil. A solução completa para médicos que buscam eficiência e simplicidade.</p>
                            <nav aria-label="Primary actions">
                                <a href="https://plantao.prontuariofacil.com.br/cadastrar" id="botao-teste-gratis" className="plantao-page-hero-button">Teste Grátis</a>
                                <a href="https://plantao.prontuariofacil.com.br/login" className="plantao-page-hero-button">Faça Login</a>
                            </nav>
                        </div>
                        <div className="plantao-page-hero-image" role="img" aria-label="Imagem ilustrativa do sistema">
                            {/* Imagem ilustrativa do sistema */}
                        </div>
                    </header>

                    {/* Features Section */}
                    <section id="features" className="plantao-page-features-section">
                        <div className="plantao-page-features-content">
                            <h2>Funcionalidades Essenciais para a Gestão Médica</h2>
                            <div className="plantao-page-features-grid">
                                <article className="plantao-page-feature-item">
                                    <h3>Gestão de Plantões Médicos</h3>
                                    <p>Organize seus plantões de forma intuitiva, com acesso rápido a todas as informações que você precisa, em qualquer dispositivo.</p>
                                </article>
                                <article className="plantao-page-feature-item">
                                    <h3>Agenda Médica Online</h3>
                                    <p>Mantenha sua agenda médica sempre atualizada, sincronizando compromissos e horários de forma fácil e rápida.</p>
                                </article>
                                <article className="plantao-page-feature-item">
                                    <h3>Gestão de Recebíveis</h3>
                                    <p>Acompanhe seus recebíveis de planos de saúde, garantindo controle financeiro com relatórios detalhados e notificações automáticas.</p>
                                </article>
                                <article className="plantao-page-feature-item">
                                    <h3>Atendimentos e Observações</h3>
                                    <p>Registre, edite e acesse rapidamente o histórico de cada paciente, com suporte a formatação avançada para suas observações.</p>
                                </article>
                                <article className="plantao-page-feature-item">
                                    <h3>Gestão de Equipes Médicas</h3>
                                    <p>Administre sua equipe com ferramentas que facilitam o gerenciamento de tarefas, escalas e plantões de forma colaborativa.</p>
                                </article>
                            </div>
                            <div className="plantao-page-features-image" role="img" aria-label="Imagem ilustrativa das funcionalidades">
                                {/* Imagem ilustrativa das funcionalidades */}
                            </div>
                        </div>
                    </section>

                    {/* Testimonials Section */}
                    <section id="testimonials" className="plantao-page-testimonials-section">
                        <div className="plantao-page-testimonials-content">
                            <h2>O Que os Médicos Dizem sobre o Plantão Fácil</h2>
                            <div className="plantao-page-testimonials-grid">
                                <article className="plantao-page-testimonial-item">
                                    <blockquote>"Desde que comecei a usar o Plantão Fácil, minha vida profissional ficou muito mais organizada. É uma ferramenta indispensável para médicos que querem otimizar seu tempo."</blockquote>
                                    <h4>Dr. João Silva</h4>
                                    <span>Cardiologista</span>
                                </article>
                                <article className="plantao-page-testimonial-item">
                                    <blockquote>"O Plantão Fácil me permitiu focar mais no atendimento aos meus pacientes, enquanto ele cuida de toda a parte administrativa. Recomendo a todos os médicos."</blockquote>
                                    <h4>Dra. Maria Oliveira</h4>
                                    <span>Clínica Geral</span>
                                </article>
                            </div>
                        </div>
                    </section>

                    {/* Pricing Section */}
                    <section id="pricing" className="plantao-page-pricing-section">
                        <div className="plantao-page-pricing-content">
                            <h2>Planos Flexíveis para Qualquer Necessidade Médica</h2>
                            <p>Escolha o plano que melhor se adapta à sua prática médica. Desde a gestão básica de plantões até funcionalidades avançadas para grandes clínicas.</p>
                            <div className="plantao-page-pricing-grid">
                                <article className="plantao-page-pricing-card">
                                    <h3>Plano Básico</h3>
                                    <p className="plantao-page-pricing-price">Gratuito</p>
                                    <ul className="plantao-page-pricing-features" aria-label="Funcionalidades do plano básico">
                                        <li>Gestão de Plantões</li>
                                        <li>Agenda Médica</li>
                                        <li>Controle de Atendimentos</li>
                                    </ul>
                                    <a href="https://plantao.prontuariofacil.com.br/cadastrar" className="plantao-page-pricing-button">Começar Agora</a>
                                </article>
                                <article className="plantao-page-pricing-card plantao-page-pricing-card-featured">
                                    <h3>Plano Profissional</h3>
                                    <p className="plantao-page-pricing-price">R$ 69/mês</p>
                                    <ul className="plantao-page-pricing-features" aria-label="Funcionalidades do plano profissional">
                                        <li>Inclui tudo do Plano Básico</li>
                                        <li>Gestão de Recebíveis</li>
                                        <li>Suporte Prioritário</li>
                                    </ul>
                                    <a href="https://plantao.prontuariofacil.com.br/cadastrar" className="plantao-page-pricing-button">Assinar Agora</a>
                                </article>
                                <article className="plantao-page-pricing-card">
                                    <h3>Plano Enterprise</h3>
                                    <p className="plantao-page-pricing-price">R$ 199/mês</p>
                                    <ul className="plantao-page-pricing-features" aria-label="Funcionalidades do plano enterprise">
                                        <li>Inclui tudo do Plano Profissional</li>
                                        <li>Gestão de Equipes Médicas</li>
                                        <li>Integração com ERPs</li>
                                    </ul>
                                    <a href="https://plantao.prontuariofacil.com.br/cadastrar" className="plantao-page-pricing-button">Assinar Agora</a>
                                </article>
                            </div>
                        </div>
                    </section>

                    <section id="faq" className="plantao-page-faq-section">
                        <div className="plantao-page-faq-content">
                            <h2>Perguntas Frequentes</h2>

                            <div className="plantao-page-faq-item">
                                <button className="plantao-page-faq-question" onClick={(e) => toggleAccordion(e)}>
                                    O que é o Plantão Fácil?
                                </button>
                                <div className="plantao-page-faq-answer">
                                    <p>O Plantão Fácil é um software que facilita a gestão de plantões médicos, permitindo que os profissionais de saúde organizem suas agendas, controlem seus recebíveis e gerenciem suas equipes de maneira eficiente.</p>
                                </div>
                            </div>

                            <div className="plantao-page-faq-item">
                                <button className="plantao-page-faq-question" onClick={(e) => toggleAccordion(e)}>
                                    Como posso começar a usar o Plantão Fácil?
                                </button>
                                <div className="plantao-page-faq-answer">
                                    <p>Você pode se cadastrar gratuitamente no nosso site e começar a usar o Plantão Fácil imediatamente. O cadastro é rápido e simples, sem compromisso.</p>
                                </div>
                            </div>

                            <div className="plantao-page-faq-item">
                                <button className="plantao-page-faq-question" onClick={(e) => toggleAccordion(e)}>
                                    Quais são os benefícios do Plantão Fácil para médicos?
                                </button>
                                <div className="plantao-page-faq-answer">
                                    <p>O Plantão Fácil oferece uma série de benefícios, incluindo a gestão centralizada de plantões, organização da agenda médica, controle de recebíveis de planos de saúde, e ferramentas avançadas para o gerenciamento de equipes médicas.</p>
                                </div>
                            </div>

                            <div className="plantao-page-faq-item">
                                <button className="plantao-page-faq-question" onClick={(e) => toggleAccordion(e)}>
                                    O Plantão Fácil é seguro?
                                </button>
                                <div className="plantao-page-faq-answer">
                                    <p>Sim, o Plantão Fácil utiliza criptografia de ponta a ponta e segue as melhores práticas de segurança para garantir que os dados dos médicos e pacientes estejam protegidos.</p>
                                </div>
                            </div>

                            <div className="plantao-page-faq-item">
                                <button className="plantao-page-faq-question" onClick={(e) => toggleAccordion(e)}>
                                    O Plantão Fácil oferece suporte técnico?
                                </button>
                                <div className="plantao-page-faq-answer">
                                    <p>Sim, oferecemos suporte técnico prioritário para todos os nossos usuários, especialmente para aqueles que assinam os planos profissionais e enterprise.</p>
                                </div>
                            </div>

                            <div className="plantao-page-faq-item">
                                <button className="plantao-page-faq-question" onClick={(e) => toggleAccordion(e)}>
                                    Quais são os planos de assinatura disponíveis?
                                </button>
                                <div className="plantao-page-faq-answer">
                                    <p>Oferecemos três planos de assinatura: Básico (gratuito), Profissional, e Enterprise. Cada plano é desenhado para atender diferentes necessidades, desde médicos individuais até grandes clínicas.</p>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* Call to Action Section */}
                    <section className="plantao-page-cta-section">
                        <div className="plantao-page-cta-content">
                            <h2>Transforme Sua Rotina com Plantão Fácil</h2>
                            <p>Descubra como o Plantão Fácil pode otimizar sua gestão de plantões e facilitar seu dia a dia. Comece agora mesmo e veja a diferença.</p>
                            <a href="https://plantao.prontuariofacil.com.br/cadastrar" className="plantao-page-cta-button" id="botao-cadastrar-conta">Cadastrar-se Gratuitamente</a>
                        </div>
                    </section>
                </main>
            </div>
        </Layout>
    );
};

export default LandingPage;

